<template>
    <div class="m2 wrapper wrapper--default">
        <h3 class="text-center m-2">Voertuigen: {{ kind }}, <span v-if="structure == 'none'"></span><span v-else> {{
            structure }},</span> {{ brand }}</h3>
        <button class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
        <hr>
        <Loading v-if="loading" />
        <div class="flexbox _row" v-else>

            <div v-for="(status, key) in items" :key="key" class="flex-grow-1 m-1">
                <h3 v-if="key == 'vehicles_in_p'" class="text-center">Voertuigen in P</h3>
                <h3 v-else-if="key == 'vehicles_in_s'" class="text-center">Voertuigen in S</h3>
                <h3 v-else class="text-center">Verkocht afgelopen week</h3>
                <hr>
                <table :class="`w-100 table__border--default`">
                    <thead :class="`table__head--default`">
                        <th style="max-width: 75px;">itemnummer</th>
                        <th>Type</th>
                        <th>Inkoper</th>
                        <th>Reg jaar</th>
                        <th>Inkoopbedrag</th>
                        <th>Vraagprijs</th>
                        <th v-if="key === 'vehicles_in_s'">Stadagen</th>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in status" :key="key">
                            <td class="text-center table__cell--default">
                                <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
                            </td>
                            <td class="text-center table__cell--default">{{ item.typenaam }}</td>
                            <td class="table__data--left table__cell--default">{{ item.inkopernaam }}</td>
                            <td class="text-center table__cell--default">{{ item.regjaar }}</td>
                            <td class="table__data--right table__cell--default" v-if="item.statuscode === 'P'">€ {{
                                item.inkoopprijs }}</td>
                            <td class="table__data--right table__cell--default" v-else>€ {{ item.inkoopbedrag }}</td>
                            <td class="table__data--right table__cell--default">€ {{ item.vraagprijs }}</td>
                            <td v-if="item.statuscode === 'S'" class="text-center table__cell--default">{{ item.stadagen }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
import request from '@/functions/request.js';
import ImageHover from '@/components/ImageHover.vue'
import * as XLSX from "xlsx"

export default {
    props: ['bu', 'kind', 'structure', 'brand'],
    components: { Loading, ImageHover },
    data: () => ({
        items: null,
        loading: true
    }),
    created() {
        this.getData(this.bu, this.kind, this.structure, this.brand)
    },
    methods: {
        getData(bu, kind, structure, brand) {
            if (structure == '') {
                structure = 'Overig';
            }
            request(`purchaseduo/items/${bu}/${kind}/${structure}/${brand}`, "GET").then(({ items }) => {
                this.items = items;
                this.loading = false;
            })
        },

        createExcel() {
            let items_list_p = [];
            let items_list_s = [];
            let items_list_v =[];
            this.items['vehicles_in_p'].forEach((i) => {
                items_list_p.push({
                    "Item": i.nummer,
                    "Type": i.typenaam,
                    "Inkoper": i.inkopernaam,
                    "Reg jaar": i.regjaar,
                    "Inkoopbedrag": i.inkoopprijs,
                    "Vraagprijs": i.vraagprijs,
                });
            });

            this.items['vehicles_in_s'].forEach((i) => {
                items_list_s.push({
                    "Item": i.nummer,
                    "Type": i.typenaam,
                    "Inkoper": i.inkopernaam,
                    "Reg jaar": i.regjaar,
                    "Inkoopbedrag": i.inkoopbedrag,
                    "Vraagprijs": i.vraagprijs,
                    "Stadagen": i.stadagen
                });
            });

            this.items['vehicles_in_v'].forEach((i) => {
                items_list_v.push({
                    "Item": i.nummer,
                    "Type": i.typenaam,
                    "Inkoper": i.inkopernaam,
                    "Reg jaar": i.regjaar,
                    "Inkoopbedrag": i.inkoopbedrag,
                    "Vraagprijs": i.vraagprijs,
                    "Stadagen": i.stadagen
                });
            });

            var itemsP = XLSX.utils.json_to_sheet(items_list_p);
            var itemsS = XLSX.utils.json_to_sheet(items_list_s);
            var itemsV = XLSX.utils.json_to_sheet(items_list_v);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                wb,
                itemsP,
                `Doorloopsnelheid_voertuigen_P`
            );
            XLSX.utils.book_append_sheet(
                wb,
                itemsS,
                `Doorloopsnelheid_voertuigen_S`
            );
            XLSX.utils.book_append_sheet(
                wb,
                itemsV,
                `Verkocht_laatste_week`
            );
            XLSX.writeFile(
                wb,
                `Doorloopsnelheid_${this.kind}_${this.structure}_${this.brand}_${this.moment().format("DD-MM-YYYY")}.xlsx`
            );
        },
    }
};
</script>